<script>
import Layout from "@/router/layouts/main";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
  computed: {
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Sobre') }}</h4>
        </div>
      </div>
    </div>

    <iframe src="https://docs.google.com/gview?url=https://s3.m2n.com.br/elomobiliarios/about/consultor-club.pdf&embedded=true" style="width:100%; height:600px;" frameborder="0"></iframe>
  </Layout>
</template>
